import type { OpenAPIV3_1 } from "openapi-types";

import ApiEndpoint from "@/apiSpec/ApiEndpoint";
import type ApiSpec from "@/apiSpec/ApiSpec";
import HttpMethods from "@/apiSpec/HttpMethods";

export class ApiPath {
  public readonly endpoints: ApiEndpoint[] = [];

  constructor(
    public readonly pathName: string,
    public readonly spec: ApiSpec,
    private readonly document: OpenAPIV3_1.PathItemObject
  ) {
    this.buildEndpoints();
  }

  public url(params?: Record<string, string>): string {
    if (!params) {
      return this.spec.baseUrl + this.pathName;
    } else {
      return (
        this.spec.baseUrl +
        this.pathParts
          .map(({ name, isParam }) => {
            if (isParam) {
              return params[name] ?? `{${name}}`;
            } else {
              return name;
            }
          })
          .join("")
      );
    }
  }

  private buildEndpoints() {
    Object.values(HttpMethods).forEach((method) => {
      const methodDocument = this.document[method];
      if (methodDocument !== undefined) {
        this.endpoints.push(new ApiEndpoint(this, method, methodDocument));
      }
    });
  }

  public registerTags(apiSpec: ApiSpec): void {
    (Object.values(this.endpoints) as ApiEndpoint[]).forEach((endpoint) =>
      endpoint.registerTags(apiSpec)
    );
  }

  public getEndpoint(method: HttpMethods): ApiEndpoint | undefined {
    return this.endpoints.find((endpoint) => endpoint.method === method);
  }

  public matchesQuery(query: string): boolean {
    return this.pathName.includes(query);
  }

  public endpointsForTag(tagName: string): ApiEndpoint[] {
    return this.endpoints.filter((endpoint) => endpoint.hasTag(tagName));
  }

  public get pathParts(): Array<{ name: string; isParam: boolean }> {
    const allParts: Array<{ name: string; isParam: boolean }> = [];
    const nonParamParts = this.pathName.split(/\{[^}]+\}/g);
    const paramParts = this.pathName.match(/\{[^}]+\}/g);
    nonParamParts.forEach((part, index) => {
      if (part !== "") {
        allParts.push({ name: part, isParam: false });
      }
      if (paramParts && paramParts[index] !== undefined) {
        allParts.push({
          name: paramParts[index].replace(/[{}]/g, ""),
          isParam: true,
        });
      }
    });
    return allParts;
  }

  public get docs(): unknown {
    return this.spec.resolveReferencesRecursive(this.document);
  }
}

export default ApiPath;
