
import { mapState } from "pinia";
import { defineComponent } from "vue";

import PathCard from "@/components/Endpoint/PathCard.vue";
import TagPaths from "@/components/Endpoint/TagPaths.vue";
import environmentStore from "@/store/environmentStore";

export default defineComponent({
  data: () => ({
    search: "",
    groupByTag: true,
  }),
  computed: {
    ...mapState(environmentStore, ["selectedEnvironment"]),
  },
  components: {
    TagPaths,
    PathCard,
  },
});
