
import { mapActions } from "pinia";
import { defineComponent } from "vue";

import AppLayout from "@/components/Layout/AppLayout.vue";
import environmentStore from "@/store/environmentStore";

export default defineComponent({
  components: { AppLayout },
  methods: {
    ...mapActions(environmentStore, ["loadStoredEnvironments"]),
  },
  async created() {
    this.loadStoredEnvironments();
  },
});
