import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-bs-target"]
const _hoisted_2 = { class: "badge bg-secondary rounded-pill ms-1 align-top opacity-50" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_tag_paths = _resolveComponent("tag-paths", true)!
  const _component_path_card = _resolveComponent("path-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h4", {
      class: "endpoints-tag-title fs-5 d-flex",
      "data-bs-toggle": "collapse",
      "data-bs-target": `#tag-${_ctx.tag.slug}-paths`,
      role: "button"
    }, [
      _createVNode(_component_vue_feather, {
        type: "chevron-right",
        class: "me-1 flex-shrink-0"
      }),
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_ctx.toTitleCase(_ctx.tag.properName)) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.tag.endpointCount), 1)
      ])
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      class: "collapse ps-4",
      id: `tag-${_ctx.tag.slug}-paths`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tag.subtags, (subtag) => {
        return (_openBlock(), _createBlock(_component_tag_paths, {
          key: subtag.name,
          tag: subtag
        }, null, 8, ["tag"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tag.endpointsByPath, ({ path }) => {
        return (_openBlock(), _createBlock(_component_path_card, {
          key: path.pathName,
          path: path,
          tag: _ctx.tag,
          class: "mb-2"
        }, null, 8, ["path", "tag"]))
      }), 128))
    ], 8, _hoisted_3)
  ]))
}