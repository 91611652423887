import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/components/Views/HomeView.vue"),
    props: ({ query }) => ({
      pathName: query.pathName,
      method: query.method,
      rawDocs: query.rawDocs === "true",
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
