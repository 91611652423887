import type { ApiAuthenticationType } from "@/apiSpec/ApiEndpoint";
import type {
  ApiAdminSessionRequestData,
  ApiAdminSessionResponseData,
} from "@/apiSpec/authentication/ApiAdminSession";
import ApiAdminSession from "@/apiSpec/authentication/ApiAdminSession";
import type {
  ApiProducerSessionRequestData,
  ApiProducerSessionResponseData,
} from "@/apiSpec/authentication/ApiProducerSession";
import ApiProducerSession from "@/apiSpec/authentication/ApiProducerSession";
import type ApiSession from "@/apiSpec/authentication/ApiSession";
import type {
  ApiUserSessionRequestData,
  ApiUserSessionResponseData,
} from "@/apiSpec/authentication/ApiUserSession";
import ApiUserSession from "@/apiSpec/authentication/ApiUserSession";
import type {
  ApiWorkerSessionRequestData,
  ApiWorkerSessionResponseData,
} from "@/apiSpec/authentication/ApiWorkerSession";
import ApiWorkerSession from "@/apiSpec/authentication/ApiWorkerSession";

export function createApiSession(
  type: "producer",
  requestData: ApiProducerSessionRequestData | null,
  responseData?: ApiProducerSessionResponseData | null
): ApiProducerSession;
export function createApiSession(
  type: "user",
  requestData: ApiUserSessionRequestData | null,
  responseData?: ApiUserSessionResponseData | null
): ApiUserSession;
export function createApiSession(
  type: "admin",
  requestData: ApiAdminSessionRequestData | null,
  responseData?: ApiAdminSessionResponseData | null
): ApiAdminSession;
export function createApiSession(
  type: "worker",
  requestData: ApiWorkerSessionRequestData | null,
  responseData?: ApiWorkerSessionResponseData | null
): ApiWorkerSession;
export function createApiSession(
  type: Exclude<ApiAuthenticationType, "none">,
  requestData: Record<string, unknown> | null,
  responseData?: Record<string, unknown> | null
): ApiSession;
export function createApiSession(
  type: Exclude<ApiAuthenticationType, "none">,
  requestData: Record<string, unknown> | null,
  responseData: Record<string, unknown> | null = null
): ApiSession {
  switch (type) {
    case "producer":
      return new ApiProducerSession(
        requestData as ApiProducerSessionRequestData | null,
        responseData as ApiProducerSessionResponseData | null
      );
    case "user":
      return new ApiUserSession(
        requestData as ApiUserSessionRequestData | null,
        responseData as ApiUserSessionResponseData | null
      );
    case "admin":
      return new ApiAdminSession(
        requestData as ApiAdminSessionRequestData | null,
        responseData as ApiAdminSessionResponseData | null
      );
    case "worker":
      return new ApiWorkerSession(
        requestData as ApiWorkerSessionRequestData | null,
        responseData as ApiWorkerSessionResponseData | null
      );
  }
}
