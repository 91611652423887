/**
 * Convert a string to kebab case.
 * Should remove spaces if there are any.
 */
export const toKebabCase = (str: string): string => {
  return str
    .split(/\s*(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join("-");
};

export const toTitleCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};
