
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import environmentStore from "@/store/environmentStore";

export default defineComponent({
  data: () => ({
    newEnvironment: {
      name: "",
      baseUrl: "",
      docsToken: "",
      sensitive: false,
    },
  }),
  computed: {
    ...mapState(environmentStore, ["environments", "selectedEnvironment"]),
  },
  methods: {
    ...mapActions(environmentStore, [
      "addEnvironment",
      "selectEnvironment",
      "removeEnvironment",
    ]),
    goToEnvironment(id: number) {
      this.selectEnvironment(id);
      this.$emit("select-environment", id);
    },
    submitNewEnvironment() {
      this.addEnvironment(
        this.newEnvironment.baseUrl,
        this.newEnvironment.name,
        this.newEnvironment.docsToken,
        this.newEnvironment.sensitive
      );
      this.newEnvironment.baseUrl = "";
      this.newEnvironment.name = "";
      this.newEnvironment.docsToken = "";
      this.newEnvironment.sensitive = false;
    },
  },
});
