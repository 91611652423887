
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import environmentStore from "@/store/environmentStore";
import { toTitleCase } from "@/utils/casing";

export default defineComponent({
  data: () => ({
    newSession: {
      type: "producer" as "producer" | "user" | "admin" | "worker",
      usernameOrEmail: "",
      workerName: "",
      password: "",
      token: "",
    },
  }),
  computed: {
    ...mapState(environmentStore, ["selectedEnvironment"]),
  },
  methods: {
    ...mapActions(environmentStore, [
      "addProducerSession",
      "addUserSession",
      "addAdminSession",
      "addWorkerSession",
      "removeSession",
    ]),
    toTitleCase,
    submitNewSession() {
      switch (this.newSession.type) {
        case "producer":
          this.addProducerSession(
            this.newSession.usernameOrEmail,
            this.newSession.password
          );
          break;
        case "user":
          this.addUserSession(
            this.newSession.usernameOrEmail,
            this.newSession.password
          );
          break;
        case "admin":
          this.addAdminSession(this.newSession.token);
          break;
        case "worker":
          this.addWorkerSession(
            this.newSession.workerName,
            this.newSession.password
          );
          break;
      }
    },
  },
});
