/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    config.metadata = { startTime: performance.now() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    response.config.metadata!.endTime = performance.now();
    response.duration =
      response.config.metadata!.endTime! - response.config.metadata!.startTime!;
    return response;
  },
  function (error) {
    error.config.metadata.endTime = performance.now();
    if (error.response) {
      error.response.duration =
        error.config.metadata.endTime - error.config.metadata.startTime;
    } else {
      error.duration =
        error.config.metadata.endTime - error.config.metadata.startTime;
    }
    return Promise.reject(error);
  }
);
