import { defineStore } from "pinia";
import type { UnwrapRef } from "vue";

import ApiEnvironment from "@/apiSpec/ApiEnvironment";
import type ApiSession from "@/apiSpec/authentication/ApiSession";
import { createApiSession } from "@/apiSpec/authentication/createApiSession";

export const environmentStore = defineStore("environment", {
  state: () => ({
    _environments: [] as ApiEnvironment[],
    _selectedEnvironmentId: null as number | null,
  }),
  getters: {
    environments(): UnwrapRef<ApiEnvironment[]> {
      return this._environments;
    },
    environment(): (
      id: number | null
    ) => UnwrapRef<ApiEnvironment> | undefined {
      return (id) => this._environments.find((env) => env.id === id);
    },
    selectedEnvironment(): UnwrapRef<ApiEnvironment> | null {
      return this.environment(this._selectedEnvironmentId) ?? null;
    },
  },
  actions: {
    loadStoredEnvironments() {
      const { environments, selectedEnvironmentId } =
        ApiEnvironment.loadStoredEnvironments();
      this._environments = environments;
      this._selectedEnvironmentId = selectedEnvironmentId;
    },
    addEnvironment(
      baseUrl: string,
      name: string,
      docsToken: string,
      sensitive: boolean
    ) {
      const environment = new ApiEnvironment(
        baseUrl,
        name,
        docsToken,
        sensitive
      );
      this._environments.push(environment);
      ApiEnvironment.updateStoredEnvironments(this._environments);
    },
    removeEnvironment(environmentId: number) {
      this._environments = this._environments.filter(
        (env) => env.id !== environmentId
      );
      if (this._selectedEnvironmentId === environmentId) {
        this._selectedEnvironmentId = null;
      }
      ApiEnvironment.updateStoredEnvironments(this._environments);
    },
    selectEnvironment(environmentId: number | null) {
      this._selectedEnvironmentId = environmentId;
      ApiEnvironment.storeSelectedEnvironment(environmentId);
    },
    addSession(session: ApiSession) {
      if (this.selectedEnvironment) {
        this.selectedEnvironment.addSession(session);
        ApiEnvironment.updateStoredEnvironments(this._environments);
      } else {
        throw new Error("There is no selected environment.");
      }
    },
    addProducerSession(usernameOrEmail: string, password: string) {
      const session = createApiSession("producer", {
        usernameOrEmail,
        password,
      });
      this.addSession(session);
    },
    addUserSession(usernameOrEmail: string, password: string) {
      const session = createApiSession("user", {
        usernameOrEmail: usernameOrEmail,
        password,
      });
      this.addSession(session);
    },
    addAdminSession(token: string) {
      const session = createApiSession("admin", { token });
      this.addSession(session);
    },
    addWorkerSession(name: string, password: string) {
      const session = createApiSession("worker", { name, password });
      this.addSession(session);
    },
    removeSession(sessionId: number) {
      if (this.selectedEnvironment) {
        this.selectedEnvironment.removeSession(sessionId);
      } else {
        throw new Error("There is no selected environment.");
      }
    },
  },
});

export default environmentStore;
