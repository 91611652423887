type FSMTransitionHandler<States extends string> = (
  fromState: States,
  toState: States
) => boolean | void | Promise<boolean | void>;

type FSMTransitions<States extends string, Transitions extends string> = Record<
  Transitions,
  {
    targets: Partial<Record<States, States>>;
    handler: FSMTransitionHandler<States> | undefined;
  }
>;

export interface FSMOptions<States extends string, Transitions extends string> {
  start: States;
  transitions: {
    name: Transitions;
    from: States;
    to: States;
  }[];
  handlers?: Partial<Record<Transitions, FSMTransitionHandler<States>>>;
}

export class FSM<States extends string, Transitions extends string> {
  private _state: States;
  private transitions: FSMTransitions<States, Transitions>;

  private defineTransitionNameIfNeeded(transitionName: Transitions) {
    if (this.transitions[transitionName] === undefined) {
      this.transitions[transitionName] = {
        targets: {},
        handler: undefined,
      };
    }
  }

  private defineTransition(
    transitionName: Transitions,
    fromState: States,
    toState: States
  ) {
    this.defineTransitionNameIfNeeded(transitionName);
    const transition = this.transitions[transitionName];
    if (transition.targets[fromState] !== undefined) {
      throw new Error(
        `Multiple target states defined for transition '${String(
          transitionName
        )}' and origin state '${String(fromState)}'`
      );
    }
    transition.targets[fromState] = toState;
  }

  private defineTransitionHandler(
    transitionName: Transitions,
    transitionHandler: FSMTransitionHandler<States>
  ) {
    this.defineTransitionNameIfNeeded(transitionName);
    this.transitions[transitionName].handler = transitionHandler;
  }

  constructor(options: FSMOptions<States, Transitions>) {
    this._state = options.start;
    this.transitions = {} as FSMTransitions<States, Transitions>;
    options.transitions.forEach(({ name, from, to }) =>
      this.defineTransition(name, from, to)
    );
    if (options.handlers !== undefined) {
      const handlers = options.handlers;
      (Object.keys(handlers) as Transitions[]).forEach((transitionName) => {
        this.defineTransitionHandler(
          transitionName,
          handlers[transitionName] as FSMTransitionHandler<States>
        );
      });
    }
  }

  public get state(): States {
    return this._state;
  }

  public async transition(transitionName: Transitions): Promise<void> {
    if (this.transitions[transitionName] === undefined) {
      throw new Error(`Transition '${String(transitionName)}' is not defined`);
    }
    const targetState = this.transitions[transitionName].targets[
      this._state
    ] as States | undefined;
    if (targetState === undefined) {
      throw new Error(
        `Transition '${String(
          transitionName
        )}' is not available from current state '${String(this._state)}'`
      );
    } else {
      let shouldTransition = true;
      const handler = this.transitions[transitionName].handler;
      if (handler !== undefined) {
        const handlerResult = handler(this._state, targetState);
        if (handlerResult === false) {
          shouldTransition = false;
        }
      }
      if (shouldTransition) {
        this._state = targetState;
      }
    }
  }
}

export default FSM;
