import axios from "axios";

import ApiSession from "@/apiSpec/authentication/ApiSession";

export type ApiWorkerSessionRequestData = {
  name: string;
  password: string;
};

export type ApiWorkerSessionResponseData = {
  name: string;
  auth_token: string;
};

export class ApiWorkerSession extends ApiSession<
  ApiWorkerSessionRequestData,
  ApiWorkerSessionResponseData
> {
  protected async authenticate(): Promise<ApiWorkerSessionResponseData> {
    if (this.environment) {
      if (this.requestData) {
        const response = await axios.post(
          `${this.environment.baseUrl}/workers/login`,
          { name: this.requestData.name, password: this.requestData.password }
        );
        return response.data;
      } else {
        throw Error("Cannot authenticate without request data.");
      }
    } else {
      throw Error("Cannot authenticate unbound session.");
    }
  }

  public get headers(): Record<string, string> {
    if (this.responseData) {
      return {
        "X-Authorization-Worker-Name": this.responseData.name,
        "X-Authorization-Token": this.responseData.auth_token,
      };
    } else {
      return {};
    }
  }

  public get type(): "worker" {
    return "worker";
  }

  public get heading(): string {
    return (
      this.responseData?.name ?? this.requestData?.name ?? "Unknown Worker"
    );
  }

  public get subheadings(): null {
    return null;
  }

  protected responseDataToJSON(): ApiWorkerSessionResponseData | undefined {
    return this.responseData
      ? {
          name: this.responseData.name,
          auth_token: this.responseData.auth_token,
        }
      : undefined;
  }
}

export default ApiWorkerSession;
