import axios from "axios";

import ApiSession from "@/apiSpec/authentication/ApiSession";

export type ApiProducerSessionRequestData = {
  usernameOrEmail: string;
  password: string;
};

export type ApiProducerSessionResponseData = {
  username: string;
  auth_token: string;
  name: string;
  surname: string;
  organization: {
    name: string;
  };
};

export class ApiProducerSession extends ApiSession<
  ApiProducerSessionRequestData,
  ApiProducerSessionResponseData
> {
  protected async authenticate(): Promise<ApiProducerSessionResponseData> {
    if (this.environment) {
      if (this.requestData) {
        const response = await axios.post(
          `${this.environment.baseUrl}/producers/login`,
          {
            username_or_email: this.requestData.usernameOrEmail,
            password: this.requestData.password,
          }
        );
        return response.data;
      } else {
        throw Error("Cannot authenticate without request data.");
      }
    } else {
      throw Error("Cannot authenticate unbound session.");
    }
  }

  public get headers(): Record<string, string> {
    if (this.responseData) {
      return {
        "X-Authorization-Username": this.responseData.username,
        "X-Authorization-Token": this.responseData.auth_token,
      };
    } else {
      return {};
    }
  }

  public get type(): "producer" {
    return "producer";
  }

  public get heading(): string {
    return this.responseData
      ? `${this.responseData.name} ${this.responseData.surname}`
      : this.requestData?.usernameOrEmail ?? "Unknown Producer";
  }

  public get subheadings(): string[] | null {
    return this.responseData
      ? [this.responseData.username, this.responseData.organization.name]
      : null;
  }

  protected responseDataToJSON(): ApiProducerSessionResponseData | undefined {
    if (this.responseData) {
      return {
        name: this.responseData.name,
        surname: this.responseData.surname,
        username: this.responseData.username,
        auth_token: this.responseData.auth_token,
        organization: {
          name: this.responseData.organization.name,
        },
      };
    } else {
      return undefined;
    }
  }
}

export default ApiProducerSession;
