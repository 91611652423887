import axios from "axios";

import ApiSession from "@/apiSpec/authentication/ApiSession";

export type ApiAdminSessionRequestData = {
  token: string;
};

export type ApiAdminSessionResponseData = {
  token: string;
};

export class ApiAdminSession extends ApiSession<
  ApiAdminSessionRequestData,
  ApiAdminSessionResponseData
> {
  protected async authenticate(): Promise<ApiAdminSessionResponseData> {
    if (this.environment) {
      if (this.requestData) {
        // Make dummy request to verify token is correct (there is no dedicated endpoint yet)
        await axios.get(`${this.environment.baseUrl}/admin/organizations`, {
          headers: { "X-Authorization-Admin-Token": this.requestData.token },
        });
        return { token: this.requestData.token };
      } else {
        throw Error("Cannot authenticate without request data.");
      }
    } else {
      throw Error("Cannot authenticate unbound session.");
    }
  }

  public get headers(): Record<string, string> {
    if (this.responseData) {
      return {
        "X-Authorization-Admin-Token": this.responseData.token,
      };
    } else {
      return {};
    }
  }

  public get type(): "admin" {
    return "admin";
  }

  public get heading(): string {
    return "Admin";
  }

  public get subheadings(): null {
    return null;
  }

  protected responseDataToJSON(): ApiAdminSessionResponseData | undefined {
    if (this.responseData) {
      return {
        token: this.responseData.token,
      };
    } else {
      return undefined;
    }
  }
}

export default ApiAdminSession;
