
import type { PropType, UnwrapRef } from "vue";
import { defineComponent } from "vue";

import type ApiTag from "@/apiSpec/ApiTag";
import PathCard from "@/components/Endpoint/PathCard.vue";
import { toTitleCase } from "@/utils/casing";

export default defineComponent({
  props: {
    tag: { type: Object as PropType<UnwrapRef<ApiTag>>, required: true },
  },
  methods: {
    toTitleCase,
  },
  components: {
    PathCard,
  },
});
