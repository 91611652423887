/* eslint-disable import/order */
import { createApp } from "vue";
import { DateTime, Duration } from "luxon";

import "@fontsource/oxygen";
import "@fontsource/oxygen-mono";
import "@/styles/bootstrap.scss";
import "@/styles/vendor.scss";
import "@/styles/styles.scss";

import "bootstrap";
import VueFeather from "vue-feather";
import Toast, { POSITION } from "vue-toastification";

import "@/axios";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(Toast, { position: POSITION.BOTTOM_RIGHT })
  .component(VueFeather.name, VueFeather);

app.config.globalProperties.$luxon = DateTime;
app.config.globalProperties.$luxonDuration = Duration;

app.mount("#app");
