
import type { PropType, UnwrapRef } from "vue";
import { defineComponent } from "vue";

import type ApiEndpoint from "@/apiSpec/ApiEndpoint";
import type ApiPath from "@/apiSpec/ApiPath";
import type ApiTag from "@/apiSpec/ApiTag";
import PathWithParams from "@/components/Endpoint/PathWithParams.vue";

export default defineComponent({
  props: {
    path: {
      type: Object as PropType<UnwrapRef<ApiPath>>,
      required: true,
    },
    tag: {
      type: Object as PropType<UnwrapRef<ApiTag>>,
    },
  },
  computed: {
    endpoints(): UnwrapRef<ApiEndpoint>[] {
      return this.tag
        ? this.path.endpointsForTag(this.tag.name)
        : this.path.endpoints;
    },
  },
  components: {
    PathWithParams,
  },
});
