import type { OpenAPIV3_1 } from "openapi-types";

import type ApiEndpoint from "@/apiSpec/ApiEndpoint";
import type ApiPath from "@/apiSpec/ApiPath";
import type ApiSpec from "@/apiSpec/ApiSpec";
import { toKebabCase } from "@/utils/casing";

export class ApiTag {
  public readonly name: string;
  public readonly subtags: Record<string, ApiTag> = {};
  public readonly endpoints: ApiEndpoint[] = [];

  private document?: OpenAPIV3_1.TagObject;

  constructor(
    nameOrDocument: string | OpenAPIV3_1.TagObject,
    private readonly spec: ApiSpec,
    public readonly parent: ApiTag | null = null
  ) {
    if (typeof nameOrDocument === "string") {
      this.name = nameOrDocument;
    } else {
      this.document = nameOrDocument;
      this.name = nameOrDocument.name;
    }
  }

  public get slug(): string {
    return toKebabCase(this.name.replace("/", "-"));
  }

  public get properName(): string {
    const splitTagName = this.name.split("/");
    return splitTagName[splitTagName.length - 1].trim();
  }

  public get description(): string | undefined {
    return this.document?.description;
  }

  public get endpointCount(): number {
    return (
      this.endpointsByPath.length +
      Object.values(this.subtags)
        .map((s) => s.endpointCount)
        .reduce((a, b) => a + b, 0)
    );
  }

  public updateDocument(document: OpenAPIV3_1.TagObject): void {
    if (document.name !== this.name) {
      throw Error(
        `Couldn't update tag ${this.name} with document: names do not match.`
      );
    }
    this.document = document;
  }

  public getSubtag(name: string): ApiTag | undefined {
    return this.subtags[name];
  }

  public addSubtag(subtag: ApiTag): void {
    const properSubtagName = subtag.properName;
    if (!this.subtags[properSubtagName]) {
      this.subtags[properSubtagName] = subtag;
    } else {
      throw Error(
        `Tag ${this.name} already has a subtag with name ${properSubtagName}`
      );
    }
  }

  public addEndpoint(endpoint: ApiEndpoint): void {
    if (!this.endpoints.includes(endpoint)) {
      this.endpoints.push(endpoint);
    }
  }

  public get endpointsByPath(): Array<{
    path: ApiPath;
    endpoints: ApiEndpoint[];
  }> {
    const ret: Array<{ path: ApiPath; endpoints: ApiEndpoint[] }> = [];

    this.endpoints.forEach((endpoint) => {
      let pathEntry = ret.find(
        ({ path }) => endpoint.path.pathName === path.pathName
      );
      if (!pathEntry) {
        pathEntry = { path: endpoint.path, endpoints: [] };
        ret.push(pathEntry);
      }
      pathEntry.endpoints.push(endpoint);
    });

    return ret;
  }
}

export default ApiTag;
