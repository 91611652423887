import type { UnwrapRef } from "vue";
import { reactive } from "vue";

import type { ApiAuthenticationType } from "@/apiSpec/ApiEndpoint";
import ApiSpec from "@/apiSpec/ApiSpec";
import type ApiSession from "@/apiSpec/authentication/ApiSession";
import type { ApiSessionJSON } from "@/apiSpec/authentication/ApiSession";
import { createApiSession } from "@/apiSpec/authentication/createApiSession";
import FSM from "@/fsm";

interface ApiEnvironmentJSON {
  id: number;
  baseUrl: string;
  name: string;
  docsToken?: string;
  sensitive: boolean;
  sessions: ApiSessionJSON[];
}
export class ApiEnvironment {
  private static lastAssignedId = 0;

  public static loadStoredEnvironments(): {
    environments: ApiEnvironment[];
    selectedEnvironmentId: number | null;
  } {
    const storedEnvironments = localStorage.getItem("environments");
    const storedSelectedEnvironmentId = JSON.parse(
      localStorage.getItem("selectedEnvironment") ?? "null"
    );
    if (storedEnvironments) {
      const environmentsJSON = JSON.parse(storedEnvironments);
      let selectedEnvironmentId: number | null = null;
      const environments = environmentsJSON.map(
        ({
          id,
          baseUrl,
          name,
          docsToken,
          sensitive,
          sessions,
        }: ApiEnvironmentJSON) => {
          const environment = new ApiEnvironment(
            baseUrl,
            name,
            docsToken,
            sensitive
          );
          if (id === storedSelectedEnvironmentId) {
            selectedEnvironmentId = environment.id;
          }
          sessions.map((sessionJSON: ApiSessionJSON) => {
            const session = createApiSession(
              sessionJSON.type,
              null,
              sessionJSON.responseData
            );
            environment.addSession(session);
          });
          return environment;
        }
      );
      return { environments, selectedEnvironmentId };
    } else {
      return { environments: [], selectedEnvironmentId: null };
    }
  }

  public static updateStoredEnvironments(
    environments: UnwrapRef<ApiEnvironment>[]
  ): void {
    localStorage.setItem(
      "environments",
      JSON.stringify(environments.map((environment) => environment.toJSON()))
    );
  }

  public static storeSelectedEnvironment(environmentId: number | null): void {
    localStorage.setItem("selectedEnvironment", JSON.stringify(environmentId));
  }

  public id = ++ApiEnvironment.lastAssignedId;
  public spec: ApiSpec | null = null;
  public sessions: ApiSession[] = [];

  private fsm = reactive(
    new FSM({
      start: "loading",
      transitions: [
        { name: "load", from: "loading", to: "loaded" },
        { name: "error", from: "loading", to: "errored" },
        { name: "reload", from: "loaded", to: "loading" },
        { name: "reload", from: "errored", to: "loading" },
      ],
    })
  );

  constructor(
    public baseUrl: string,
    public name: string,
    public docsToken?: string,
    public sensitive = false
  ) {
    this.getApiSpec();
  }

  public async refresh(): Promise<void> {
    await this.reloadApiSpec();
  }

  public get state(): "loading" | "loaded" | "errored" {
    return this.fsm.state;
  }

  private async getApiSpec() {
    try {
      this.spec = await ApiSpec.forEnvironment(this, this.docsToken);
      this.fsm.transition("load");
    } catch (err) {
      console.error(err);
      this.fsm.transition("error");
    }
  }

  private async reloadApiSpec() {
    this.fsm.transition("reload");
    await this.getApiSpec();
  }

  public addSession(session: ApiSession): void {
    this.sessions.push(session);
    session.bindToEnvironment(this);
  }

  public removeSession(sessionId: number): void {
    this.sessions = this.sessions.filter((session) => session.id !== sessionId);
  }

  public getLastUsedSession(type: ApiAuthenticationType): ApiSession | null {
    return (
      this.sessions
        .filter((session) => session.type === type && session.usable)
        .sort((a, b) => {
          if (a.lastUsed === null && b.lastUsed === null) {
            return 0;
          } else if (a.lastUsed === null) {
            return 1;
          } else if (b.lastUsed === null) {
            return -1;
          } else {
            return b.lastUsed > a.lastUsed
              ? 1
              : b.lastUsed < a.lastUsed
              ? -1
              : 0;
          }
        })[0] ?? null
    );
  }

  public toJSON(): ApiEnvironmentJSON {
    return {
      id: this.id,
      baseUrl: this.baseUrl,
      name: this.name,
      docsToken: this.docsToken,
      sensitive: this.sensitive,
      sessions: this.sessions
        .filter((session) => session.usable)
        .map((session) => session.toJSON()),
    };
  }
}

export default ApiEnvironment;
