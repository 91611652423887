import type { OpenAPIV3_1 } from "openapi-types";

import type ApiEnvironment from "@/apiSpec/ApiEnvironment";
import type ApiPath from "@/apiSpec/ApiPath";
import type ApiSpec from "@/apiSpec/ApiSpec";
import type ApiTag from "@/apiSpec/ApiTag";
import type HttpMethods from "@/apiSpec/HttpMethods";
import type ApiSession from "@/apiSpec/authentication/ApiSession";
import { openApiSchemaToJsonSchema } from "@/utils/json";

export type ApiAuthenticationType =
  | "producer"
  | "user"
  | "admin"
  | "worker"
  | "none";

const isReference = (
  parameter: unknown
): parameter is OpenAPIV3_1.ReferenceObject => {
  return (
    typeof parameter === "object" && parameter !== null && "$ref" in parameter
  );
};

export class ApiEndpoint {
  public readonly tags: ApiTag[] = [];
  public readonly parameters: OpenAPIV3_1.ParameterObject[];

  constructor(
    public readonly path: ApiPath,
    public readonly method: HttpMethods,
    public document: OpenAPIV3_1.OperationObject
  ) {
    this.parameters = this.resolveParameters();
  }

  public get spec(): ApiSpec {
    return this.path.spec;
  }

  public get bodySchema(): any {
    const openAPISchema = this.document.requestBody
      ? (this.spec.resolveReferencesRecursive(this.document.requestBody) as any)
          ?.content["application/json"]?.schema ?? {}
      : {};
    return openApiSchemaToJsonSchema(openAPISchema);
  }

  public get queryFields(): {
    name: string;
    type: string;
    required: boolean;
    example?: unknown;
  }[] {
    return this.resolveParameters()
      .filter((p) => p.in === "query")
      .map((p) => {
        const type = (p.schema as OpenAPIV3_1.SchemaObject).type;
        return {
          name: p.name,
          type:
            (typeof type === "string" ? type : type?.toString()) ?? "string",
          required: p.required ?? false,
          example: p.example,
        };
      });
  }

  private resolveParameters(): OpenAPIV3_1.ParameterObject[] {
    if (this.document.parameters) {
      return this.document.parameters.map((parameter) => {
        if ("$ref" in parameter) {
          return this.spec.resolveReference(
            parameter
          ) as OpenAPIV3_1.ParameterObject;
        } else {
          return parameter;
        }
      });
    } else {
      return [];
    }
  }

  public get authenticationType(): ApiAuthenticationType {
    const headerParamRefs = (
      (this.document.parameters?.filter((parameter) =>
        isReference(parameter)
      ) ?? []) as OpenAPIV3_1.ReferenceObject[]
    ).map(({ $ref }) => $ref);
    if (
      headerParamRefs.includes("#/components/parameters/ProducerTokenHeader")
    ) {
      return "producer";
    } else if (
      headerParamRefs.includes("#/components/parameters/UserTokenHeader")
    ) {
      return "user";
    } else if (
      headerParamRefs.includes("#/components/parameters/AdminTokenHeader")
    ) {
      return "admin";
    } else if (
      headerParamRefs.includes("#/components/parameters/WorkerTokenHeader")
    ) {
      return "worker";
    } else {
      const authenticationHeaders = this.authenticationHeaders.map((header) =>
        header.name.replace("X-Authorization-", "")
      );
      if (authenticationHeaders.includes("Username")) {
        return "producer";
      } else if (authenticationHeaders.includes("Admin-Token")) {
        return "admin";
      } else if (authenticationHeaders.includes("Worker-Name")) {
        return "worker";
      }
    }
    return "none";
  }

  private get authenticationHeaders(): OpenAPIV3_1.ParameterObject[] {
    return this.parameters.filter(
      (parameter) =>
        parameter.in === "header" &&
        parameter.name.startsWith("X-Authorization-")
    );
  }

  public registerTags(apiSpec: ApiSpec): void {
    if (this.document.tags) {
      this.document.tags.forEach((tagName) => {
        let tag = apiSpec.getTag(tagName);
        if (!tag) {
          tag = apiSpec.addOrUpdateTag(tagName);
        }
        this.tags.push(tag);
        tag.addEndpoint(this);
      });
    }
  }

  public hasTag(tagName: string): boolean {
    return this.tags.some((tag) => tag.name === tagName);
  }

  public get summary(): string {
    return this.document.summary ?? "";
  }

  public get description(): string {
    return this.document.description ?? "";
  }

  public get deprecated(): boolean {
    return this.document.deprecated ?? false;
  }

  public get environment(): ApiEnvironment {
    return this.spec.environment;
  }

  public getDefaultSession(): ApiSession | null {
    return this.environment.getLastUsedSession(this.authenticationType);
  }
}

export default ApiEndpoint;
