import axios from "axios";
import type { OpenAPIV3_1 } from "openapi-types";

import type ApiEnvironment from "@/apiSpec/ApiEnvironment";
import ApiPath from "@/apiSpec/ApiPath";
import ApiTag from "@/apiSpec/ApiTag";

export type ApiSpecTag = OpenAPIV3_1.TagObject & {
  paths: Record<string, OpenAPIV3_1.PathItemObject>;
  subtags?: ApiSpecTag[];
};

export type ApiSpecPaths = OpenAPIV3_1.PathsObject;

export class ApiSpec {
  public readonly tags: ApiTag[] = [];
  public readonly paths: ApiPath[] = [];

  static async forEnvironment(
    environment: ApiEnvironment,
    docsToken?: string
  ): Promise<ApiSpec> {
    const response = await axios.get(`${environment.baseUrl}/api-docs.json`, {
      headers: docsToken ? { "X-Api-Docs-Token": docsToken } : {},
    });
    return new ApiSpec(response.data, environment);
  }

  constructor(
    private document: OpenAPIV3_1.Document,
    public environment: ApiEnvironment
  ) {
    this.buildTags();
    this.buildPaths();
  }

  public get baseUrl(): string {
    return `${this.environment.baseUrl}`;
  }

  private buildTags() {
    if (this.document.tags) {
      this.document.tags.forEach((tag) => this.addOrUpdateTag(tag));
    }
  }

  private buildPaths() {
    if (this.document.paths) {
      Object.entries(this.document.paths).forEach(([path, document]) => {
        if (document) {
          this.addPath(path, document);
        }
      });
    }
  }

  public addOrUpdateTag(
    nameOrDocument: string | OpenAPIV3_1.TagObject
  ): ApiTag {
    let tag;
    const fullName =
      typeof nameOrDocument === "string" ? nameOrDocument : nameOrDocument.name;
    const splitName = fullName.split("/");
    if (splitName.length === 1) {
      tag = this.tags.find((tag) => tag.name === fullName);
      if (!tag) {
        tag = new ApiTag(nameOrDocument, this);
        this.tags.push(tag);
      }
    } else {
      let currentParent = this.tags.find((tag) => tag.name === splitName[0]);
      if (!currentParent) {
        currentParent = new ApiTag(splitName[0], this);
        this.tags.push(currentParent);
      }
      for (let i = 1; i < splitName.length - 1; i++) {
        const previousParent: ApiTag = currentParent;
        currentParent = previousParent.getSubtag(splitName[i]);
        if (!currentParent) {
          currentParent = new ApiTag(
            splitName.slice(0, i + 1).join("/"),
            this,
            previousParent
          );
          previousParent.addSubtag(currentParent);
        }
      }
      const properTagName = splitName[splitName.length - 1];
      tag = currentParent.getSubtag(properTagName);
      if (!tag) {
        tag = new ApiTag(nameOrDocument, this, currentParent);
        currentParent.addSubtag(tag);
      }
    }
    if (typeof nameOrDocument === "object") {
      tag.updateDocument(nameOrDocument);
    }
    return tag;
  }

  private addPath(pathName: string, document: OpenAPIV3_1.PathItemObject) {
    const path = new ApiPath(pathName, this, document);
    path.registerTags(this);
    this.paths.push(path);
  }

  public getTag(fullName: string): ApiTag | undefined {
    const splitName = fullName.split("/");
    let currentParent = this.tags.find((tag) => tag.name === splitName[0]);
    if (!currentParent) {
      return undefined;
    }
    for (let i = 1; i < splitName.length; i++) {
      currentParent = currentParent.getSubtag(splitName[i]);
      if (!currentParent) {
        return undefined;
      }
    }
    return currentParent;
  }

  public getPath(pathName: string): ApiPath | undefined {
    return this.paths.find((path) => path.pathName === pathName);
  }

  public resolveReference(ref: OpenAPIV3_1.ReferenceObject): unknown {
    const refParts = ref.$ref.split("/");
    let current: unknown = this.document;
    for (let i = 1; i < refParts.length; i++) {
      if (
        typeof current === "object" &&
        current !== null &&
        refParts[i] in current
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        current = (current as any)[refParts[i]];
      } else {
        throw new Error(`Invalid reference: ${ref.$ref}`);
      }
    }
    return current;
  }

  public resolveReferenceOrValue(
    refOrValue: OpenAPIV3_1.ReferenceObject | unknown
  ): unknown {
    if (
      typeof refOrValue === "object" &&
      refOrValue !== null &&
      "$ref" in refOrValue
    ) {
      return this.resolveReference(refOrValue as OpenAPIV3_1.ReferenceObject);
    } else {
      return refOrValue;
    }
  }

  public resolveReferencesRecursive(object: unknown): unknown {
    if (Array.isArray(object)) {
      return object.map((item) => this.resolveReferencesRecursive(item));
    } else if (
      typeof object === "object" &&
      object !== null &&
      "$ref" in object
    ) {
      return this.resolveReferencesRecursive(
        this.resolveReference(object as OpenAPIV3_1.ReferenceObject)
      );
    } else if (typeof object === "object" && object !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = {};
      Object.entries(object).forEach(([key, value]) => {
        result[key] = this.resolveReferencesRecursive(value);
      });
      return result;
    } else {
      return object;
    }
  }

  public searchPaths(query: string): ApiPath[] {
    if (query === "") {
      return this.paths;
    } else {
      return this.paths.filter((path) => path.matchesQuery(query));
    }
  }
}

export default ApiSpec;
