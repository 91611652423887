
import { DateTime } from "luxon";
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    timestamp: {
      mode: "current" as "current" | "subtract" | "add",
      difference: {
        value: 10,
        unit: "days" as
          | "seconds"
          | "minutes"
          | "hours"
          | "days"
          | "weeks"
          | "months"
          | "years",
      },
    },
  }),
  computed: {
    timestampValue(): string {
      let timestamp: string;
      switch (this.timestamp.mode) {
        case "current":
          timestamp = DateTime.now().toISO()!;
          break;
        case "subtract":
          timestamp = DateTime.now()
            .minus({
              [this.timestamp.difference.unit]: this.timestamp.difference.value,
            })
            .toISO()!;
          break;
        case "add":
          timestamp = DateTime.now()
            .plus({
              [this.timestamp.difference.unit]: this.timestamp.difference.value,
            })
            .toISO()!;
          break;
      }
      return timestamp;
    },
  },
  methods: {
    copyTimestampToClipboard() {
      navigator.clipboard.writeText(this.timestampValue);
    },
  },
});
