
import { mapState } from "pinia";
import { defineComponent } from "vue";

import SidebarEndpoints from "@/components/Layout/SidebarEndpoints.vue";
import SidebarEnvironments from "@/components/Layout/SidebarEnvironments.vue";
import SidebarSessions from "@/components/Layout/SidebarSessions.vue";
import SidebarTools from "@/components/Layout/SidebarTools.vue";
import environmentStore from "@/store/environmentStore";

export default defineComponent({
  data: () => ({
    mode: "environments" as "environments" | "sessions" | "endpoints" | "tools",
  }),
  computed: {
    ...mapState(environmentStore, ["selectedEnvironment"]),
  },
  created() {
    if (this.selectedEnvironment) {
      this.mode = "endpoints";
    }
  },
  watch: {
    selectedEnvironment() {
      this.mode = "endpoints";
    },
  },
  components: {
    SidebarEnvironments,
    SidebarSessions,
    SidebarEndpoints,
    SidebarTools,
  },
});
