import { reactive } from "vue";

import type { ApiAuthenticationType } from "@/apiSpec/ApiEndpoint";
import type ApiEnvironment from "@/apiSpec/ApiEnvironment";
import FSM from "@/fsm";
import { toTitleCase } from "@/utils/casing";

export type ApiSessionJSON = {
  type: Exclude<ApiAuthenticationType, "none">;
  responseData?: Record<string, unknown>;
};

export abstract class ApiSession<
  RequestDataType extends Record<string, unknown> = Record<string, unknown>,
  ResponseDataType extends Record<string, unknown> = Record<string, unknown>
> {
  private static lastAssignedId = 0;

  public id = ++ApiSession.lastAssignedId;
  private fsm = reactive(
    new FSM({
      start: "unbound",
      transitions: [
        { name: "bind", from: "unbound", to: "unchecked" },
        { name: "startAuthentication", from: "unbound", to: "authenticating" },
        { name: "authenticate", from: "authenticating", to: "authenticated" },
        { name: "authenticate", from: "unchecked", to: "authenticated" },
        { name: "error", from: "authenticating", to: "errored" },
        { name: "error", from: "unchecked", to: "errored" },
      ],
    })
  );
  protected environment: ApiEnvironment | null = null;
  public lastUsed: Date | null = null;

  constructor(
    protected requestData: RequestDataType | null = null,
    protected responseData: ResponseDataType | null = null
  ) {}

  public async bindToEnvironment(environment: ApiEnvironment): Promise<void> {
    this.environment = environment;
    if (this.responseData !== null) {
      this.fsm.transition("bind");
    } else {
      await this.authenticateWithStateTransitions();
    }
  }

  private async authenticateWithStateTransitions(): Promise<void> {
    try {
      this.fsm.transition("startAuthentication");
      this.responseData = await this.authenticate();
      this.fsm.transition("authenticate");
    } catch (err) {
      this.fsm.transition("error");
    }
  }

  protected abstract authenticate(): Promise<ResponseDataType>;

  public abstract get type(): Exclude<ApiAuthenticationType, "none">;
  public abstract get heading(): string;
  public abstract get subheadings(): string[] | null;

  protected abstract get headers(): Record<string, string>;

  public getHeaders(): Record<string, string> {
    return this.headers;
  }

  public get state(): ApiSession["fsm"]["state"] {
    return this.fsm.state;
  }

  public toString(): string {
    switch (this.state) {
      case "authenticated":
      case "unchecked": {
        const type = toTitleCase(this.type);
        let res =
          this.heading === type ? this.heading : `${type}: ${this.heading}`;
        if (this.subheadings) {
          res += ` (${this.subheadings.join(", ")})`;
        }
        return res;
      }
      case "authenticating":
        return `${toTitleCase(this.type)}: Authenticating...`;
      default:
        return `${toTitleCase(this.type)}: Error`;
    }
  }

  protected abstract responseDataToJSON(): ResponseDataType | undefined;
  public toJSON(): ApiSessionJSON {
    return {
      type: this.type,
      responseData: this.responseDataToJSON(),
    };
  }

  public get usable(): boolean {
    return this.state === "authenticated" || this.state === "unchecked";
  }

  markAsUsed(successful: boolean): void {
    this.lastUsed = new Date();
    if (successful && this.state === "unchecked") {
      this.fsm.transition("authenticate");
    }
  }
}

export default ApiSession;
