
import { defineComponent } from "vue";

import AppSidebar from "@/components/Layout/AppSidebar.vue";

export default defineComponent({
  computed: {},
  components: {
    AppSidebar,
  },
});
