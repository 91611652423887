
import type { PropType, UnwrapRef } from "vue";
import { defineComponent } from "vue";

import type ApiPath from "@/apiSpec/ApiPath";

export default defineComponent({
  props: {
    path: {
      type: Object as PropType<UnwrapRef<ApiPath>>,
      required: true,
    },
  },
});
