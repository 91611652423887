import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card card-clickable" }
const _hoisted_2 = { class: "card-body py-2" }
const _hoisted_3 = { class: "fs-6 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_path_with_params = _resolveComponent("path-with-params")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_link, {
      to: {
        name: 'home',
        query: {
          pathName: _ctx.path.pathName,
          method: _ctx.endpoints[0]?.method,
        },
      },
      class: "text-decoration-none text-reset"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", _hoisted_3, [
              _createVNode(_component_path_with_params, { path: _ctx.path }, null, 8, ["path"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.endpoints, (endpoint) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: endpoint.method,
                class: _normalizeClass(["badge rounded-pill text-white me-1 text-decoration-none", `bg-${endpoint.authenticationType}`]),
                to: {
              name: 'home',
              query: {
                pathName: _ctx.path.pathName,
                method: endpoint.method,
              },
            }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(endpoint.method.toUpperCase()), 1)
                ]),
                _: 2
              }, 1032, ["class", "to"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}